import PusherLink from './PusherLink';
import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Pusher from 'pusher-js';

const PUSHER_API_KEY = '568f381bdd95dd48c614';
const PUSHER_CLUSTER = 'eu';
export const API_LOCATION = 'https://dev.smartvote.sk';
const BEARER_TOKEN = 'abcd';

export const pusher = new Pusher(PUSHER_API_KEY, {
  cluster: PUSHER_CLUSTER,
  authEndpoint: `${API_LOCATION}/graphql/subscriptions/auth`,
  auth: {
    headers: {
      authorization: BEARER_TOKEN,
    },
  },
});

const pusherLink = new PusherLink({
  pusher,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      console.log({ locations });
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([
  errorLink,
  pusherLink,
  new HttpLink({ uri: `${API_LOCATION}/graphql` }),
]);

export const apiClient = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
