import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  DateTime: any;
  Upload: any;
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sO`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any;
};

export type Query = {
  __typename?: 'Query';
  sessions?: Maybe<SessionPaginator>;
  session?: Maybe<Session>;
  sessionByQrHash?: Maybe<Session>;
  /** @deprecated Field no longer supported */
  sessionPointVoting?: Maybe<SessionPointVoting>;
  /** @deprecated Field no longer supported */
  activePointOrVoting?: Maybe<SessionPointOrSessionPointVoting>;
  voting?: Maybe<SessionPointVoting>;
  sessionByTvHash?: Maybe<Session>;
  me?: Maybe<AdminUser>;
  /** @deprecated Field no longer supported */
  userByActivationCode?: Maybe<AdminUser>;
  appNeedsToBeUpdated: Scalars['Boolean'];
};


export type QuerySessionsArgs = {
  where?: Maybe<SessionFilters>;
  orderBy?: Maybe<Array<SessionsOrderByOrderByClause>>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QuerySessionArgs = {
  id: Scalars['Int'];
};


export type QuerySessionByQrHashArgs = {
  qr_hash: Scalars['String'];
};


export type QuerySessionPointVotingArgs = {
  id: Scalars['ID'];
};


export type QueryActivePointOrVotingArgs = {
  session_id: Scalars['ID'];
};


export type QueryVotingArgs = {
  id: Scalars['ID'];
};


export type QuerySessionByTvHashArgs = {
  tv_hash: Scalars['String'];
};


export type QueryUserByActivationCodeArgs = {
  code: Scalars['String'];
};


export type QueryAppNeedsToBeUpdatedArgs = {
  app_version: Scalars['String'];
  api_version: Scalars['String'];
};

export type SessionFilters = {
  session_type_id?: Maybe<Array<Scalars['ID']>>;
  session_state?: Maybe<Array<SessionState>>;
  starts_at?: Maybe<DateRange>;
  workspace_id?: Maybe<Array<Scalars['ID']>>;
};

export enum SessionState {
  NotActiveState = 'NOT_ACTIVE_STATE',
  InitialCheckinState = 'INITIAL_CHECKIN_STATE',
  ActiveState = 'ACTIVE_STATE',
  FinishedState = 'FINISHED_STATE'
}

export type DateRange = {
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};


/** Order by clause for the `orderBy` argument on the query `sessions`. */
export type SessionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: SessionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for the `orderBy` argument on the query `sessions`. */
export enum SessionsOrderByColumn {
  StartsAt = 'STARTS_AT'
}

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** A paginated list of Session items. */
export type SessionPaginator = {
  __typename?: 'SessionPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Session items. */
  data: Array<Session>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  title: Scalars['String'];
  place: Scalars['String'];
  qr_hash: Scalars['String'];
  session_state: SessionState;
  starts_at: Scalars['DateTime'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  workspace: Workspace;
  sessionType: SessionType;
  sessionPoints: Array<SessionPoint>;
  sessionWorkspaceAccount?: Maybe<SessionWorkspaceAccount>;
  sessionWorkspaceAccounts?: Maybe<Array<SessionWorkspaceAccount>>;
};


export type Workspace = {
  __typename?: 'Workspace';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type SessionType = {
  __typename?: 'SessionType';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type SessionPoint = {
  __typename?: 'SessionPoint';
  id: Scalars['ID'];
  text: Scalars['String'];
  order_path: Scalars['String'];
  is_active: Scalars['Boolean'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  childSessionPoints: Array<SessionPoint>;
  votings?: Maybe<Array<SessionPointVoting>>;
};

export type SessionPointVoting = {
  __typename?: 'SessionPointVoting';
  id: Scalars['ID'];
  text: Scalars['String'];
  order_column?: Maybe<Scalars['Int']>;
  order_column_visible?: Maybe<Scalars['Int']>;
  is_active: Scalars['Boolean'];
  vote_state: Scalars['String'];
  checkin_timer_value?: Maybe<Scalars['Int']>;
  voting_timer_value?: Maybe<Scalars['Int']>;
  checkin_starts_at?: Maybe<Scalars['DateTime']>;
  checkin_ends_at?: Maybe<Scalars['DateTime']>;
  voting_starts_at?: Maybe<Scalars['DateTime']>;
  voting_ends_at?: Maybe<Scalars['DateTime']>;
  server_time: Scalars['DateTime'];
  quorum_type: Scalars['String'];
  quorum_type_label: Scalars['String'];
  is_checkin_required: Scalars['Boolean'];
  has_anonymized_results: Scalars['Boolean'];
  session_point_id: Scalars['Int'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  votes?: Maybe<Array<SessionPointWorkspaceAccountVote>>;
  myVote?: Maybe<SessionPointWorkspaceAccountVote>;
  votesSummary: VotesSummary;
  checkinSummary: CheckinSummaryCount;
  result?: Maybe<VotingResult>;
  sessionPoint?: Maybe<SessionPoint>;
};

export type SessionPointWorkspaceAccountVote = {
  __typename?: 'SessionPointWorkspaceAccountVote';
  session_workspace_account_id: Scalars['Int'];
  checkin_value?: Maybe<Scalars['Boolean']>;
  vote_value?: Maybe<VoteValue>;
  device_id?: Maybe<Scalars['String']>;
  gps_lat?: Maybe<Scalars['String']>;
  gps_lng?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  sessionPointVoting: SessionPointVoting;
  sessionWorkspaceAccount: SessionWorkspaceAccount;
};

export enum VoteValue {
  InFavor = 'IN_FAVOR',
  Against = 'AGAINST',
  Abstained = 'ABSTAINED'
}

export type SessionWorkspaceAccount = {
  __typename?: 'SessionWorkspaceAccount';
  id: Scalars['ID'];
  invite_type: Scalars['String'];
  is_invite_sent: Scalars['Boolean'];
  is_checked_in: Scalars['Boolean'];
  can_vote_from_home: Scalars['Boolean'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  workspaceAccount: WorkspaceAccount;
  session: Session;
  workspaceAccountGroup?: Maybe<WorkspaceAccountGroup>;
};

export type WorkspaceAccount = {
  __typename?: 'WorkspaceAccount';
  id: Scalars['ID'];
  position?: Maybe<Scalars['String']>;
  workspace_id: Scalars['Int'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  workspace: Workspace;
  workspaceAccountGroups: Array<WorkspaceAccountGroup>;
  user?: Maybe<AdminUser>;
};

export type WorkspaceAccountGroup = {
  __typename?: 'WorkspaceAccountGroup';
  id: Scalars['ID'];
  title: Scalars['String'];
  workspace_id: Scalars['Int'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  workspaceAccounts: Array<WorkspaceAccount>;
  onesignal_id?: Maybe<Scalars['String']>;
  avatar_thumb_url?: Maybe<Scalars['String']>;
};

export type VotesSummary = {
  __typename?: 'VotesSummary';
  inFavor: VotesSummaryPerValue;
  against: VotesSummaryPerValue;
  abstained: VotesSummaryPerValue;
  didNotVote: VotesSummaryPerValue;
  absent: VotesSummaryPerValue;
  summary: VotesSummaryCount;
};

export type VotesSummaryPerValue = {
  __typename?: 'VotesSummaryPerValue';
  count: Scalars['Int'];
  percentage: Scalars['Float'];
  workspaceAccounts?: Maybe<Array<WorkspaceAccount>>;
  showPercentage?: Maybe<Scalars['Int']>;
};

export type VotesSummaryCount = {
  __typename?: 'VotesSummaryCount';
  totalCount: Scalars['Int'];
  quorumTotalCount: Scalars['Int'];
  didVoteCount: Scalars['Int'];
};

export type CheckinSummaryCount = {
  __typename?: 'CheckinSummaryCount';
  checkedIn: Count;
  summary: Count;
};

export type Count = {
  __typename?: 'Count';
  count: Scalars['Int'];
};

export enum VotingResult {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Repeated = 'REPEATED'
}

export type SessionPointOrSessionPointVoting = SessionPoint | SessionPointVoting;

export type Mutation = {
  __typename?: 'Mutation';
  checkIntoSession?: Maybe<SessionWorkspaceAccount>;
  checkOutFromSession?: Maybe<SessionWorkspaceAccount>;
  checkIntoSessionVoting?: Maybe<SessionPointWorkspaceAccountVote>;
  voteInSessionVoting?: Maybe<SessionPointWorkspaceAccountVote>;
  /** @deprecated Field no longer supported */
  exportSessionToPdf: Scalars['String'];
  logout: AdminUser;
  changePassword: AdminUser;
  updateUser: AdminUser;
  updateUserAvatar: AdminUser;
  login: Scalars['String'];
  /** @deprecated Field no longer supported */
  activateUser: AdminUser;
  /** @deprecated Field no longer supported */
  appNeedsToBeUpdated: Scalars['Boolean'];
};


export type MutationCheckIntoSessionArgs = {
  session_id: Scalars['ID'];
};


export type MutationCheckOutFromSessionArgs = {
  session_id: Scalars['ID'];
};


export type MutationCheckIntoSessionVotingArgs = {
  session_point_voting_id: Scalars['ID'];
  checkin_value: Scalars['Boolean'];
  device_id?: Maybe<Scalars['String']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_lng?: Maybe<Scalars['Float']>;
};


export type MutationVoteInSessionVotingArgs = {
  session_point_voting_id: Scalars['ID'];
  vote_value: VoteValue;
  device_id?: Maybe<Scalars['String']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_lng?: Maybe<Scalars['Float']>;
};


export type MutationExportSessionToPdfArgs = {
  session_id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  old_password: Scalars['String'];
  new_password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  onesignal_id?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserAvatarArgs = {
  image: Scalars['Upload'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationActivateUserArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAppNeedsToBeUpdatedArgs = {
  app_version: Scalars['String'];
  api_version: Scalars['String'];
};


export type Subscription = {
  __typename?: 'Subscription';
  sessionUpdated?: Maybe<SessionUpdatedResponse>;
};


export type SubscriptionSessionUpdatedArgs = {
  session_id?: Maybe<Scalars['ID']>;
};

export type SessionUpdatedResponse = {
  __typename?: 'SessionUpdatedResponse';
  targetSessionId: Scalars['Int'];
  action: Scalars['String'];
  data?: Maybe<SessionUpdatedUnion>;
};

export type SessionUpdatedUnion = Session | SessionPoint | SessionPointVoting | SessionWorkspaceAccount;


export enum VoteState {
  NotActiveState = 'NOT_ACTIVE_STATE',
  WaitingState = 'WAITING_STATE',
  PresentationState = 'PRESENTATION_STATE',
  WaitingForVoteState = 'WAITING_FOR_VOTE_STATE',
  VotingState = 'VOTING_STATE',
  CalculatingResultsState = 'CALCULATING_RESULTS_STATE',
  FinishedState = 'FINISHED_STATE',
  CancelledState = 'CANCELLED_STATE'
}

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type SessionUpdatedSubscriptionVariables = Exact<{
  session_id: Scalars['ID'];
}>;


export type SessionUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { sessionUpdated?: Maybe<(
    { __typename?: 'SessionUpdatedResponse' }
    & Pick<SessionUpdatedResponse, 'action'>
    & { data?: Maybe<(
      { __typename: 'Session' }
      & Pick<Session, 'id' | 'title' | 'place' | 'session_state' | 'starts_at'>
      & { sessionType: (
        { __typename?: 'SessionType' }
        & Pick<SessionType, 'title'>
      ), workspace: (
        { __typename?: 'Workspace' }
        & Pick<Workspace, 'title' | 'logo'>
      ) }
    ) | (
      { __typename: 'SessionPoint' }
      & Pick<SessionPoint, 'id' | 'text' | 'is_active' | 'order_path'>
    ) | (
      { __typename: 'SessionPointVoting' }
      & Pick<SessionPointVoting, 'id' | 'text' | 'order_column' | 'order_column_visible' | 'is_active' | 'vote_state' | 'quorum_type' | 'quorum_type_label' | 'is_checkin_required' | 'has_anonymized_results' | 'result' | 'server_time' | 'checkin_timer_value' | 'voting_timer_value' | 'checkin_starts_at' | 'checkin_ends_at' | 'voting_starts_at' | 'voting_ends_at'>
      & { votesSummary: (
        { __typename?: 'VotesSummary' }
        & { inFavor: (
          { __typename?: 'VotesSummaryPerValue' }
          & Pick<VotesSummaryPerValue, 'count' | 'percentage' | 'showPercentage'>
        ), against: (
          { __typename?: 'VotesSummaryPerValue' }
          & Pick<VotesSummaryPerValue, 'count' | 'percentage' | 'showPercentage'>
        ), abstained: (
          { __typename?: 'VotesSummaryPerValue' }
          & Pick<VotesSummaryPerValue, 'count' | 'percentage' | 'showPercentage'>
        ), didNotVote: (
          { __typename?: 'VotesSummaryPerValue' }
          & Pick<VotesSummaryPerValue, 'count' | 'percentage' | 'showPercentage'>
        ), absent: (
          { __typename?: 'VotesSummaryPerValue' }
          & Pick<VotesSummaryPerValue, 'count' | 'percentage' | 'showPercentage'>
        ), summary: (
          { __typename?: 'VotesSummaryCount' }
          & Pick<VotesSummaryCount, 'totalCount' | 'quorumTotalCount' | 'didVoteCount'>
        ) }
      ), checkinSummary: (
        { __typename?: 'CheckinSummaryCount' }
        & { checkedIn: (
          { __typename?: 'Count' }
          & Pick<Count, 'count'>
        ), summary: (
          { __typename?: 'Count' }
          & Pick<Count, 'count'>
        ) }
      ), sessionPoint?: Maybe<(
        { __typename?: 'SessionPoint' }
        & Pick<SessionPoint, 'order_path'>
      )> }
    ) | (
      { __typename: 'SessionWorkspaceAccount' }
      & Pick<SessionWorkspaceAccount, 'id'>
    )> }
  )> }
);


export const SessionUpdatedDocument = gql`
    subscription SessionUpdated($session_id: ID!) {
  sessionUpdated(session_id: $session_id) {
    action
    data {
      __typename
      ... on Session {
        id
        title
        place
        session_state
        starts_at
        sessionType {
          title
        }
        workspace {
          title
          logo
        }
      }
      ... on SessionPoint {
        id
        text
        is_active
        order_path
      }
      ... on SessionPointVoting {
        id
        text
        order_column
        order_column_visible
        is_active
        vote_state
        quorum_type
        quorum_type_label
        is_checkin_required
        has_anonymized_results
        result
        server_time
        checkin_timer_value
        voting_timer_value
        checkin_starts_at
        checkin_ends_at
        voting_starts_at
        voting_ends_at
        votesSummary {
          inFavor {
            count
            percentage
            showPercentage
          }
          against {
            count
            percentage
            showPercentage
          }
          abstained {
            count
            percentage
            showPercentage
          }
          didNotVote {
            count
            percentage
            showPercentage
          }
          absent {
            count
            percentage
            showPercentage
          }
          summary {
            totalCount
            quorumTotalCount
            didVoteCount
          }
        }
        checkinSummary {
          checkedIn {
            count
          }
          summary {
            count
          }
        }
        sessionPoint {
          order_path
        }
      }
      ... on SessionWorkspaceAccount {
        id
      }
    }
  }
}
    `;

/**
 * __useSessionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSessionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionUpdatedSubscription({
 *   variables: {
 *      session_id: // value for 'session_id'
 *   },
 * });
 */
export function useSessionUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionUpdatedSubscription, SessionUpdatedSubscriptionVariables>) {
        return Apollo.useSubscription<SessionUpdatedSubscription, SessionUpdatedSubscriptionVariables>(SessionUpdatedDocument, baseOptions);
      }
export type SessionUpdatedSubscriptionHookResult = ReturnType<typeof useSessionUpdatedSubscription>;
export type SessionUpdatedSubscriptionResult = Apollo.SubscriptionResult<SessionUpdatedSubscription>;