import React,  {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import { apiClient } from './config';
import { SessionUpdatedDocument } from './generated/graphql';

function App() {
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState(4);
  const [numOfClients, setNumOfClients] = useState(1);
  const [events, setEvents] = useState([]);

  const [remainingNumOfSubscriptions, setRemainingNumOfSubscriptions] = useState(0);


  function subscribeToSessionUpdate(sessionId: number) {
    const subscription = apiClient.subscribe({
      variables: { session_id: sessionId },
      query: SessionUpdatedDocument,
    });
  
    return subscription.subscribe({
      next(data) {
        const { sessionUpdated } = data.data;
        console.log({ sessionUpdated });

        if(sessionUpdated) {
          sessionUpdated.created_at = new Date();

          setEvents(events => [...events, sessionUpdated])
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }

  function start(counter:number){
    if(counter < numOfClients) {
      setTimeout(function(){
        counter++;
        subscribeToSessionUpdate(sessionId);
        start(counter);
      }, 500);
    } else {
      setLoading(false);
    }
  }

  function resubscribe(counter:number) {
    if(counter < numOfClients) {
      setTimeout(function(){
        counter++;
        const subscription = subscribeToSessionUpdate(sessionId);
        // setTimeout(function(){
          subscription.unsubscribe();
          resubscribe(counter);
        // }, 500);
      }, 500);
    } else {
      setLoading(false);
    }
  }

  function resbcribeAndReload(num:number) {
      const subscription = subscribeToSessionUpdate(sessionId);
      console.log({remainingNumOfSubscriptions});
      // setRemainingNumOfSubscriptions(remainingNumOfSubscriptions - 1);
      localStorage.setItem('remainingNumOfSubscriptions', (num - 1).toString());

      setTimeout(function(){
        subscription.unsubscribe();
        // localStorage.removeItem('pusherTransportTLS');
        window.location.reload();
      }, 500);
  }

  useEffect(() => {
    const remainingNumOfSubscriptionsLocalStorage = localStorage.getItem('remainingNumOfSubscriptions');

    if(remainingNumOfSubscriptionsLocalStorage && parseInt(remainingNumOfSubscriptionsLocalStorage) > 0 ) {
      setRemainingNumOfSubscriptions(parseInt(remainingNumOfSubscriptionsLocalStorage));
      console.log(parseInt(remainingNumOfSubscriptionsLocalStorage));
      resbcribeAndReload(parseInt(remainingNumOfSubscriptionsLocalStorage));
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        
        <div>
          <label>Session ID</label>&nbsp;

          <input 
            value={sessionId} 
            onChange={(e)=> {setSessionId(Number(e.target.value))}}
            placeholder="Session ID" 
            disabled
          />
        </div>

        <div>
          <label>Pocet subscriptions</label>&nbsp;

          <input 
            value={numOfClients} 
            onChange={(e)=> {setNumOfClients(Number(e.target.value))}}
            placeholder="Pocet klientov" />
        </div>
        <br />
        <>
          {remainingNumOfSubscriptions > 0 && (
              <p>Zostavajuce subscriptions {remainingNumOfSubscriptions}</p>
          )}

          {!loading && remainingNumOfSubscriptions  === 0 &&(
            <button 
              onClick={() => {
                // start(0);
                // resubscribe(numOfClients);
                resbcribeAndReload(numOfClients);
                setLoading(true);
              }}
              disabled={loading}
            >{loading ? 'Subscribujem': 'Subscribni'}</button>
          )}

          {loading && (<p>Subscribujem</p>)}

          <ol>
          {events && events.map((event, index) => (
            <li key={index}>
              {event.__typename} (<strong>{event.action}</strong>)- <strong>{event.created_at.getHours()}:{event.created_at.getMinutes()}:{event.created_at.getSeconds()}.{event.created_at.getMilliseconds()}</strong>
            </li>
          ))}
          </ol>
        </>
      </header>
    </div>
  );
}

export default App;
